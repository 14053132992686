import React from 'react';

import { Button } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

export default function Login({ backend_url, state }) {

  const redirect_uri = `${window.location}callback`;

  return (
    <div style={{ textAlign: "center" }}>
      <h1>Waze Data Explorer</h1>
      <Button type="primary" href={`${backend_url}/login?state=${state}&redirect_uri=${redirect_uri}`} icon={<LoginOutlined />} size="large" style={{textDecoration: "none"}}>Cliquez ici pour se connecter</Button>
    </div>
  )
}
