// credits: https://www.digitalocean.com/community/tutorials/how-to-add-login-authentication-to-react-applications
import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
        const token = localStorage.getItem('token');
        return token;
    };

    const [token, setToken] = useState(getToken());

    const saveToken = token => {
        if (token) {
            localStorage.setItem('token', token);
            setToken(token);
        } else {
            localStorage.removeItem('token');
            setToken();
        }
    };

    return {
        setToken: saveToken,
        token
    }
}
