import React, { useEffect, useState } from 'react';
import { DatePicker, Space, Badge } from 'antd';
import dayjs from 'dayjs';
import Plot from 'react-plotly.js';
import locale from "plotly.js-locales/fr";

import sumOfIntervalsWithoutOverlaps from '../js/sumOfIntervalsWithoutOverlaps.js';
import Map from '../components/Map.js';
import Login from '../components/Login.js';
import Logout from '../components/Logout.js';
// import JamTable from './components/JamTable.js';

const { RangePicker } = DatePicker;

export default function Home({ backend_url, token, setToken, state }) {

  const [selectedRoadNetworkSegmentId, setSelectedRoadNetworkSegmentId] = useState(null);
  const [jams, setJams] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [from, setFrom] = useState(dayjs('2023-04-03', 'YYYY-MM-DD').format());
  const [to, setTo] = useState(dayjs('2023-04-04', 'YYYY-MM-DD').format());
  const [plotData, setPlotData] = useState([]);
  const [congestionRatio, setCongestionRatio] = useState();
  const [eventsCount, setEventsCount] = useState();
  const [recordsCount, setRecordsCount] = useState();
  const [loadingTime, setLoadingTime] = useState();
  const [selectedFeatureId, setSelectedFeatureId] = useState("");
  const [hasFetchFailed, setHasFetchFailed] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);


  const buildPlotData = (data) => {

    const MARKER_SIZE_OFFSET = 4;
    const MARKER_SIZE_SCALING = 2;

    if (!data) {
      return [];
    }

    const groupedData = Object.groupBy(data.sort((a, b) => (a.uuid) - (b.uuid)), ({ properties }) => properties.uuid);

    const groupCount = Object.keys(groupedData).length;

    let plotData = [];

    [0, 1, 2, 3, 4, 5].forEach(
      (x) => {
        plotData.push({
          x: [null],
          y: [null],
          legendgroup: "group2",
          legendgrouptitle: {
            text: "Niveau de trafic",
            itemsizing: "trace"
          },
          name: x,
          mode: "markers",
          marker: {
            size: [MARKER_SIZE_OFFSET + MARKER_SIZE_SCALING * x],
            color: "rgb(0, 0, 0)"
          }
        })
      }
    );

    let congestedTimeSpans = [];
    for (const [key, value] of Object.entries(groupedData)) {
      congestedTimeSpans.push([Date.parse(value[0].properties._file_received_at), Date.parse(value[value.length-1].properties._file_received_at)]);
      plotData.push({
        x: value.reduce(
          (accumulator, currentValue) => accumulator.concat(currentValue.properties._file_received_at),
          [],
        ),
        y: value.reduce(
          (accumulator, currentValue) => accumulator.concat(currentValue.properties.delay / 60.),
          []
        ),
        text: value.reduce(
          (accumulator, currentValue) => accumulator.concat(`Niveau de trafic = ${currentValue.properties.level}`),
          []
        ),
        customdata: value.reduce(
          (accumulator, currentValue) => accumulator.concat(currentValue.properties._fid),
          []
        ),
        marker: {
          size: value.reduce(
            (accumulator, currentValue) => accumulator.concat(MARKER_SIZE_OFFSET + MARKER_SIZE_SCALING * currentValue.properties.level),
            []
          ),
          symbol: value.reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue.properties.orientation === -1 ? "circle" : "circle-open"),
            []
          ),
        },
        name: key,
        type: 'scatter',
        mode: 'lines+markers',
        legendgroup: "group1",
        legendgrouptitle: {
          text: "UUID"
        }
      });
    }

    // it can happen that there are multiple events overlapping esp. since we consider both directions for one segment.
    // thus we need to sum the intervals without conting overlaps multiple time (otherwise we could have more than 100% congestion)
    let congestionTime = sumOfIntervalsWithoutOverlaps(congestedTimeSpans);
    return [groupCount, plotData, congestionTime];
  }

  const handleOnRangePickerChange = (dates, dateString) => {
    setFrom(dates[0].set('hour', 0).set('minute', 0).set('second', 0).format());
    setTo(dates[1].set('hour', 23).set('minute', 59).set('second', 59).format());
  }

  const handleOnHover = (data) => {
    if (data.points.length === 1) {
      setSelectedFeatureId(data.points[0].customdata);
    }
  }

  const handleOnUnhover = () => {
    setSelectedFeatureId("");
  }

  useEffect(() => {

    const checkToken = async () => {
      const response = await fetch(`${backend_url}/checktoken`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });

      setIsTokenValid(response.ok);
    }

    checkToken();

  }, [token]);

  useEffect(() => {

    if (selectedRoadNetworkSegmentId === null) {
      return;
    }

    // see https://devtrium.com/posts/async-functions-useeffect
    const fetchData = async () => {

      setHasFetchFailed(false);
      setIsLoading(true);
      let time1 = performance.now();
      // get the data from the api
      const response = await fetch(`${backend_url}/jams?id_gm_troncon=${selectedRoadNetworkSegmentId}&from=${encodeURIComponent(from)}&to=${encodeURIComponent(to)}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      let time2 = performance.now();
      setLoadingTime(time2 - time1);

      if (!response.ok) {
        setHasFetchFailed(true);
        throw new Error(response.status);
      }

      const geojson = await response.json();
      return geojson;
    };

    fetchData()
      .then((data) => {
        setJams(data);
        if (data.features) {
          setRecordsCount(data.features.length);
          const [_eventsCount, _plotData, _congestionTime] = buildPlotData(data.features);
          setPlotData(_plotData);
          setEventsCount(_eventsCount);
          setCongestionRatio(_congestionTime / (Date.parse(to)-Date.parse(from)));
        }
        else {
          setPlotData([]);
          setEventsCount(undefined);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        // console.error(error.message);
        setPlotData([]);
        setEventsCount(undefined);
        setLoadingTime();
        setIsLoading(false);
        if (error.message === "403") {
          setToken(); // logout
        }
      });


  }, [selectedRoadNetworkSegmentId, from, to, backend_url, token, setToken]);


  if (!token || !isTokenValid) {
    return (
      <div style={{
        top: "50%",
        left: "50%",
        transform: "translate3d(-50%,-50%, 0)",
        position: "absolute"
      }}>
        <Login backend_url={backend_url} state={state} />
      </div>
    )
  }

  return (
    <div className="App">
      {/* {jams && jams.features && (
        <div style={{ background: "white", top: "50px", left: "10px", width: "700px", padding: "10px", position: "absolute", zIndex: 1 }}>
          <JamTable roadNetworkSegmentID={selectedRoadNetworkSegmentId} data={jams.features} loading={loading}></JamTable>
        </div>
      )} */}


      <div style={{ padding: "10px", background: "white", height: 400, width: "100%", bottom: "0px", left: "0px", position: "absolute", zIndex: 1 }}>
        {/* header */}
        <div style={{ width: "100%", height: "50px", background: "white", display: "flex", justifyContent: "space-between" }}>
          <Space size={'large'} >
            Veuillez choisir une plage temporelle: <RangePicker onChange={handleOnRangePickerChange} value={[dayjs(from, 'YYYY-MM-DD'), dayjs(to, 'YYYY-MM-DD')]}></RangePicker>
            {selectedRoadNetworkSegmentId && <div>ID_GM_TRONCON = {selectedRoadNetworkSegmentId}</div>}

            {!isLoading && plotData.length > 0 && (
              <>
                <div><Badge count={recordsCount} overflowCount={10000} color={"green"} /> enregistrements</div>
                <div><Badge count={eventsCount} overflowCount={10000} color={"blue"} /> événements uniques</div>
                <div><Badge count={`${Math.round(congestionRatio*100)} %`} overflowCount={10000} color={"red"} /> taux de congestion</div>
              </>
            )}
          </Space>
          {!isLoading && !hasFetchFailed && selectedRoadNetworkSegmentId && (<div style={{ color: "gray" }}>{(loadingTime / 1000).toFixed(3)} s</div>)}

        </div>
        {/* plot */}
        <div>
          <div>
            {!selectedRoadNetworkSegmentId ? <div style={{ fontSize: 30, marginTop: 120 }}>Veuillez cliquer sur un tronçon...</div> :
              (isLoading ? <div style={{ fontSize: 30, marginTop: 120 }}>Chargement des données en cours...</div> :
                (hasFetchFailed ? <div style={{ fontSize: 30, marginTop: 120 }}>Le chargement des données a échoué :-(</div> :
                  (plotData.length > 0 ?
                    <Plot
                      data={plotData}
                      useResizeHandler={true}
                      style={{ width: "100%", height: 330 }}
                      layout={{
                        autosize: true,
                        uirevision: true,
                        margin: {
                          l: 60,
                          r: 100,
                          b: 5,
                          t: 20,
                          pad: 0
                        },
                        xaxis: {
                          // title: "t",
                          range: [`${from}`, `${to}`],
                          tickformat: "%H:%M\n%a %d-%m-%y",
                          rangeslider: {
                            range: [`${from}`, `${to}`],
                          },
                          type: "date"
                        },
                        yaxis: {
                          title: "retard [min]",
                          fixedrange: false
                        },
                        legend: {
                          itemsizing: 'trace'
                        }
                      }}
                      config={{
                        locales: { fr: locale },
                        locale: "fr",
                        responsive: true
                      }}
                      onHover={handleOnHover}
                      onUnhover={handleOnUnhover}
                    /> : <div style={{ fontSize: 30, marginTop: 120 }}>Aucune donnée n'est disponible pour le tronçon sélectionné.</div>)
                )
              )
            }
          </div>
        </div>
      </div >

      {/* map */}
      <div>
        <Map
          geojsonData={jams}
          selectedItemId={selectedRoadNetworkSegmentId}
          setSelectedItemId={setSelectedRoadNetworkSegmentId}
          selectedFeatureId={selectedFeatureId}
        ></Map>
      </div >

      {/* user logout */}
      <div style={{ position: "absolute", top: "10px", left: "10px", zindex: "1" }}>
        <Logout setToken={setToken} />
      </div>

    </div >
  );
}
