/// ChatGPT prompt: "a small javascript function that takes a list of intervals and computes the sum of all intervals, without counting overlaps"
export default function sumOfIntervalsWithoutOverlaps(intervals) {
    // First, sort the intervals by their start time
    intervals.sort((a, b) => a[0] - b[0]);

    let mergedIntervals = [];

    for (const interval of intervals) {
        // If there are no merged intervals or the current interval does not overlap
        if (!mergedIntervals.length || mergedIntervals[mergedIntervals.length - 1][1] < interval[0]) {
            mergedIntervals.push(interval);
        } else {
            // Merge the intervals
            mergedIntervals[mergedIntervals.length - 1][1] = Math.max(mergedIntervals[mergedIntervals.length - 1][1], interval[1]);
        }
    }

    // Calculate the sum of lengths of merged intervals
    let sum = 0;
    for (const [start, end] of mergedIntervals) {
        sum += end - start;
    }

    return sum;
}