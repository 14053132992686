import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// see https://ant.design/docs/react/i18n
import { ConfigProvider } from 'antd';
import frFR from 'antd/locale/fr_FR';

// see https://ant.design/components/date-picker
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
dayjs.locale('fr');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ConfigProvider locale={frFR}>
      <App />
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
