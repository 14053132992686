import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

export default function Logout({ setToken }) {

    const handleClick = () => {
        setToken();
    }

    return (
        <>
            <Button icon={<LogoutOutlined />} onClick={handleClick} size="large">Se déconnecter</Button>
        </>
    )
}