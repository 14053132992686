import React, { useRef, useEffect, useState } from 'react';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';

import './Map.css';

// see https://www.npmjs.com/package/pmtiles
import { Protocol } from "pmtiles";


export default function Map({ geojsonData, selectedItemId, setSelectedItemId, selectedFeatureId }) {

    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng] = useState(6.16);
    const [lat] = useState(46.22);
    const [zoom] = useState(10.6);
    //   const [presetGeometries, setPresetGeometries] = useState(null);
    //   const [presetLabels, setPresetLabels] = useState([]);


    let protocol = new Protocol();
    maplibregl.addProtocol("pmtiles", protocol.tile);

    // Initialize the map
    useEffect(() => {
        if (map.current) {
            return; // stops map from initializing more than once
        };

        // console.log("Render map.");

        (async () => {

            const customAttribution = "<a href='https://ge.ch/sitg/fiche/7206'>Plan SITG</a>";

            const mapStyle = {
                version: 8,
                name: "Empty",
                metadata: {
                    "mapbox:autocomposite": true,
                    "mapbox:type": "template",
                },
                sources: {
                    PLAN_SITG: {
                        type: "raster",
                        tiles: [
                            "https://ge.ch/sitgags2/rest/services/RASTER/PLAN_SITG/MapServer/export?bbox={bbox-epsg-3857}&size=256,256&bboxSR=3857&&imageSR=3857&format=png&transparent=false&dpi=96&f=image&layers=",
                        ],
                        tileSize: 256,
                        attribution: customAttribution
                    },
                    roadNetwork: {
                        type: "vector",
                        url: "pmtiles:///GMO_GRAPHE_ROUTIER.pmtiles"
                    },
                    geojsonData: {
                        type: 'geojson',
                        data: {
                            "type": "FeatureCollection",
                            "features": []
                        }
                    }
                },
                layers: [
                    {
                        id: "PLAN_SITG",
                        type: "raster",
                        source: "PLAN_SITG",
                        layout: {
                            visibility: "visible",
                        },
                    },
                    {
                        id: 'road-network',
                        source: 'roadNetwork',
                        // "source-layer" est le nom de notre couche "dans" le fichier PMTiles
                        //   Si on ne l'a pas précisé à la création du PMTiles, Tippecanoe génère
                        //   un identifiant à partir du fichier source.
                        //   Il est facile de vérifier les couches d'un PMtiles en le chargeant
                        //   sur https://protomaps.github.io/PMTiles
                        'source-layer': 'GMO_GRAPHE_ROUTIER',
                        type: 'line',
                        paint: {
                            'line-color': '#198EC8',
                            'line-width': 3,
                            'line-opacity': 0.7,
                        },
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'round',
                        },
                    },
                    {
                        id: 'highlighted-road-network-segment',
                        source: 'roadNetwork',
                        'source-layer': 'GMO_GRAPHE_ROUTIER',
                        type: 'line',
                        paint: {
                            'line-color': '#198EC8',
                            'line-width': 15,
                            'line-opacity': 1,
                        },
                        layout: {
                            'line-join': 'round',
                            'line-cap': 'butt',
                        },
                        filter: ["==", "ID_GM_TRONCON", -1]
                    },
                    {
                        id: 'geojson-data',
                        type: 'line',
                        source: 'geojsonData',
                        layout: {
                            visibility: "visible",
                            'line-join': 'round',
                            'line-cap': 'round',
                        },
                        paint: {
                            "line-color": "rgba(255, 0, 0)",
                            "line-width": 3,
                            // "line-opacity": 0.02
                        },
                    },
                    {
                        id: 'highlighted-geojson-data-feature',
                        type: 'line',
                        source: 'geojsonData',
                        layout: {
                            visibility: "none",
                            'line-join': 'round',
                            'line-cap': 'round',
                        },
                        paint: {
                            "line-color": "rgba(0, 255, 0)",
                            "line-width": 10,
                            'line-opacity': 0.7,
                        }
                    },
                    {
                        id: 'highlighted-geojson-data-feature-direction',
                        type: 'symbol',
                        source: 'geojsonData',
                        layout: {
                            'symbol-placement': 'line',
                            'symbol-spacing': 50,
                            'symbol-avoid-edges': false,
                            'icon-allow-overlap': true,
                            // 'icon-ignore-placement': true,
                            'icon-rotate': 0,
                            'icon-image': 'arrow',
                            'icon-size': 1,
                            'visibility': 'none'
                        },
                        paint: {
                            "icon-color" : "rgb(0, 0, 0)",
                            // "icon-halo-color": "rgb(0, 255, 0)",
                            // "icon-halo-width": 10
                        },
                        minzoom: 10
                    }
                ]
            };

            map.current = new maplibregl.Map({
                container: mapContainer.current,
                style: mapStyle,
                center: [lng, lat],
                zoom: zoom,
                maxZoom: 18
            });

            map.current.addControl(new maplibregl.NavigationControl(), 'top-right');
            // const popup = new maplibregl.Popup({ closeButton: false, closeOnClick: false });

            map.current.on('mouseenter', 'road-network', ({ lngLat, features } = {}) => {
                map.current.getCanvas().style.cursor = 'crosshair';
                // popup
                //     .setHTML(
                //         features.map(feature => '<pre>' + JSON.stringify(feature.properties, null, 2) + '</pre>').join('</br >')
                //     )
                //     .setLngLat(lngLat)
                //     .addTo(map.current);
            });
            map.current.on('mouseleave', 'road-network', () => {
                map.current.getCanvas().style.cursor = '';
                // popup.remove();
            });

            map.current.on('click', 'road-network', (e) => {
                // console.log(e.features[0].properties.ID_GM_TRONCON);

                setSelectedItemId(e.features[0].properties.ID_GM_TRONCON);

            });

            map.current.on('load', async () => {
                // must credit the author: <a href="https://www.flaticon.com/free-icons/next" title="next icons">Next icons created by Roundicons - Flaticon</a>
                // const image = await map.current.loadImage('https://upload.wikimedia.org/wikipedia/commons/7/7c/201408_cat.png');
                const image = await map.current.loadImage('small-right-arrow.png');
                map.current.addImage('arrow', image.data, { sdf: true });
            })


        })();

    }, [lng, lat, zoom, setSelectedItemId]);

    // Display the current segment in bold
    useEffect(() => {
        if (map.current) { // it means that the map has already been initialized
            if(map.current.getLayer("highlighted-road-network-segment")) { // it means that the layer has loaded
                map.current.setFilter("highlighted-road-network-segment", ["==", "ID_GM_TRONCON", parseInt(selectedItemId)]);
            }
        }
    }, [selectedItemId]);

    // Highlight the hovered feature
    useEffect(() => {
        if (map.current) { // it means that the map has already been initialized
            if(map.current.getLayer("highlighted-geojson-data-feature-direction") && map.current.getLayer("highlighted-geojson-data-feature")) { // it means that the layers have loaded
                if (geojsonData && geojsonData.features && selectedFeatureId !== undefined) {
                    map.current.getSource('geojsonData').setData(geojsonData);
                    map.current.setFilter("highlighted-geojson-data-feature", ["==", "_fid", selectedFeatureId]);
                    map.current.setFilter("highlighted-geojson-data-feature-direction", ["==", "_fid", selectedFeatureId]);    
                    map.current.setLayoutProperty("highlighted-geojson-data-feature", "visibility", "visible");
                    map.current.setLayoutProperty("highlighted-geojson-data-feature-direction", "visibility", "visible");          
                } else {
                    map.current.setLayoutProperty("highlighted-geojson-data-feature", "visibility", "none");
                    map.current.setLayoutProperty("highlighted-geojson-data-feature-direction", "visibility", "none");
                }
            }
        }

    }, [geojsonData, selectedFeatureId]);


    return (
        <div className="map-wrap">
            <div ref={mapContainer} className="map" />
        </div>
    );
};